
<template>
  <div class="systemIndex defined_Dialog">
    <router-view></router-view>
  </div>
</template>

<script>
  export default{
    name:'systemIndex',
  }
</script>

<style lang="less" scoped="scoped">
  .systemIndex{
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    padding: 10px;
    // border: 1px solid black;
  }
</style>

<!-- 导入单据公共样式 -->
<style lang="less" src="@/less/Comm/comm_billing.less"></style>
<style lang="less" src="@/less/system/comm_system.less"></style>
